import { Injectable } from '@angular/core';
import { PermissionType } from '@app/core/enums/permission-type.enum';
import { UsersService } from '@app/modules/accounts/services/users.service';
import { BarSelectorService } from '@app/shared/services/bar-selector.service';
import { setUser } from '@app/store/actions/user.action';
import { UserItem } from '@app/store/models/user-item';
import { Store } from '@ngrx/store';
import { AuthService } from 'services/auth-service/auth.service';
import { CustomDateServiceService } from 'services/custom-date-service/custom-date-service.service';

@Injectable()
export class WorkingContextService {
  constructor(
    private identitySerivce: UsersService,
    private customDateServiceService: CustomDateServiceService,
    private authService: AuthService,
    private barSelectorService: BarSelectorService,
    private _store: Store<{ roles: UserItem }>
    ){}

  public load(){
    return new Promise((resolve,reject) => {
   
      if (this.authService.isAuthenticated()) {
        this.identitySerivce.getMyAccountInfo().subscribe(e => {
          this._store.dispatch(setUser({user : e}))
          this.customDateServiceService.setLocale(e.locale)
          if(!e.fromOldPortal && e.permissionType == PermissionType.Account && e.userAccounts){
            this.barSelectorService.setAccounts(e.userAccounts, e.activeUserAccount);
          }
          resolve(true);
        })
      }else {
        resolve(true);
      }

    })
  }
}
